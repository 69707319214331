export default {

	// api请求地址  正式环境baseUrl: '/',  开发环境baseUrl:'http://niu.hbdsteel.com/'https://www.hbdsteel.com/
	baseUrl: '/',	
	// baseUrl: 'http://niu.hbdsteel.com/',
	
	// 图片域名
	imgDomain: 'https://www.hbdsteel.com/',
	// imgDomain: 'http://niu.hbdsteel.com/',

	// 腾讯地图key
	 mpKey: '',

	// 客服
	 webSocket: '',

	// api安全
	apiSecurity: true,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: ``,

}
